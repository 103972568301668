import { put, takeLatest, select, call, fork, all, takeEvery, delay, take } from 'redux-saga/effects'

import { isMobileOnly } from 'react-device-detect'

import * as gridViewActions from '@tabeeb/modules/gridView/actions'
import * as gridViewSelectors from '@tabeeb/modules/gridView/selectors'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import {
  hideCallPreview,
  hideUserList,
  showCallPreview,
  showSelfView,
  showUserList,
} from '@tabeeb/modules/mobileWhiteboard/actions'
import { getShowCallPreview, getShowUserList } from '@tabeeb/modules/mobileWhiteboard/selectors'
import { signalrActions } from '@tabeeb/modules/signalr'
import { mobileZoomMin, ZOOM_MULTIPLIER } from '@tabeeb/modules/../users/common/mobileZoom'
import { getContentId, getIsCurrentUserPresenter } from '@tabeeb/modules/shared/content/selectors'
import * as recordingActions from '@tabeeb/modules/recording/actions'
import { LocalRecordingOwnerActionType, RecordingType, TabeebConnectEvents } from '@tabeeb/enums'
import { closeSwitchRecordingSourceDialog } from '@tabeeb/modules/whiteboard/actions'
import { openCallPreviewDialog } from '@tabeeb/modules/presentation/actions/conference'
import * as contentStateSelectors from '@tabeeb/shared/content/selectors'
import * as devicesActions from '@tabeeb/modules/presentation/actions/devices'
import * as tracksActions from '@tabeeb/modules/presentation/actions/tracks'
import * as jitsiTrackActions from '@tabeeb/modules/presentation/actions/jitsiTrack'
import * as connectionActions from '@tabeeb/modules/presentation/actions/connection'
import * as presentationSelectors from '@tabeeb/modules/presentation/selectors'
import * as trackService from '@tabeeb/modules/presentation/services/trackService'
import * as connectionService from '@tabeeb/modules/presentation/services/connectionService'
import * as conferenceService from '@tabeeb/modules/presentation/services/conferenceService'
import bindEvent from '@tabeeb/shared/utils/bindEvent'
import * as usersActions from '@tabeeb/modules/../users/actions'
import * as usersSelectors from '@tabeeb/modules/../users/selectors'
import * as muteAudioActions from '@tabeeb/modules/presentationToolbar/actions/muteAudio'
import * as muteVideoActions from '@tabeeb/modules/presentationToolbar/actions/muteVideo'
import { notificationActions } from '@tabeeb/modules/notification'
import * as accountSelectors from '@tabeeb/modules/account/selectors'
import {
  AUDIO_DEVICE,
  VIDEO_DEVICE,
  VIDEO_INPUT_KIND,
  DEFAULT_AUDIO_DEVICE_ID,
  CAMERA_FACING_MODE,
  AUDIO_INPUT_DEVICE_KEY,
  VIDEO_INPUT_DEVICE_KEY,
  AUDIO_OUTPUT_DEVICE_KEY,
  DEFAULT_VIDEO_DEVICE_ID,
  VIDEO_MUTED,
  AUDIO_MUTED,
} from '@tabeeb/modules/presentation/constants'
import { setVideoMuted } from '@tabeeb/modules/presentation/actions/jitsiTrack'
import { onVideoMuted } from '@tabeeb/modules/presentationToolbar/actions/muteVideo'

let externalEventChannels = []

function* attachTrack(action) {
  const { track, userId } = action.payload
  const attachPayload = {
    userId,
    container: null,
    trackToAttach: track,
  }
  if (track.getType() === VIDEO_DEVICE) {
    yield put(jitsiTrackActions.attachVideo(userId))
  } else {
    attachPayload.container = trackService.getAudioContainerById(userId)
    yield put(jitsiTrackActions.attachAudio(attachPayload))
  }
}

function* createLocalTracks() {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  let constraints = yield call(conferenceService.checkForDevices)
  constraints = constraints.filter((item) => item !== '')
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  if (constraints.length < 2) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'No camera or mic is detected. To make a video call please plug a webcam or mic into your device',
        options: {
          autoHideDuration: 10000,
        },
      })
    )

    yield put(usersActions.resetVideoLoadForUser(currentUserId))
    jitsiRoom.sendCommandOnce(TabeebConnectEvents.TRACKS_INITIALIZATION_FAILED, { value: currentUserId })
  }

  if (isMobileOnly) {
    yield put(connectionActions.onUserReadyForCall())
  } else {
    yield put(openCallPreviewDialog())
  }

  if (constraints.length === 0) {
    return
  }

  const isDeviceSwitchingEnabled = yield select(appConfigStateSelectors.getIsDeviceSwitchingEnabled)

  const audioInputDeviceId = yield call(conferenceService.getAudioInputDeviceId, isDeviceSwitchingEnabled)
  const audioOutputDeviceId = yield call(conferenceService.getAudioOutputDeviceId, isDeviceSwitchingEnabled)
  const videoInputDeviceId = yield call(conferenceService.getVideoInputDeviceId)

  const initTracks = {
    devices: constraints,
    resolution: window.config.resolution,
    micDeviceId: audioInputDeviceId,
    cameraDeviceId: videoInputDeviceId,
  }
  try {
    const tracks = yield call(connectionService.createLocalTracks, initTracks)
    yield put(tracksActions.setLocalTracks(tracks))
    for (let i = 0; i < tracks.length; i++) {
      yield put(jitsiTrackActions.addTrackToRoom(tracks[i]))
    }

    localStorage.setItem(VIDEO_INPUT_DEVICE_KEY, videoInputDeviceId)
    localStorage.setItem(AUDIO_INPUT_DEVICE_KEY, audioInputDeviceId)
    yield put(devicesActions.setAudioOutputDevice(audioOutputDeviceId || 'default'))
  } catch (ex) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'No camera or mic is detected. To make a video call please plug a webcam or mic into your device',
        options: {
          autoHideDuration: 10000,
        },
      })
    )
    yield put(usersActions.resetVideoLoadForUser(currentUserId))
    jitsiRoom.sendCommandOnce(TabeebConnectEvents.TRACKS_INITIALIZATION_FAILED, { value: currentUserId })
  }
}

function changeTrackMuteState(track) {
  return new Promise(function (resolve, reject) {
    const promise = track.isMuted() ? track.unmute() : track.mute()
    promise.then(() => {
      resolve()
    })
  })
}

function* muteAudio() {
  const tracks = yield select((state) => state.presentation.tracks)
  const track = tracks.localTracks.find((track) => track.getType() === AUDIO_DEVICE)

  if (!track) {
    return
  }

  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  yield call(changeTrackMuteState, track)

  yield put(jitsiTrackActions.setAudioMuted())
  yield put(muteAudioActions.onAudioMuted())
  yield put(muteAudioActions.updateRemoteAudioIndicator(currentUserId))
}

function* muteVideo() {
  const tracks = yield select((state) => state.presentation.tracks)

  const track = tracks.localTracks.find((track) => track.getType() === VIDEO_DEVICE)
  if (track) {
    yield call(changeTrackMuteState, track)
    yield put(jitsiTrackActions.setVideoMuted())
    yield put(muteVideoActions.onVideoMuted(track))
  }
}

function* stopTracks() {
  const tracks = yield select((state) => state.presentation.tracks)
  const { localTracks } = tracks
  for (let i = 0; i < localTracks.length; i++) {
    yield put(jitsiTrackActions.stopTrack(localTracks[i]))
  }
  yield put(tracksActions.resetLocalTracks())
}

function* toggleLargeVideo(action) {
  const { userId } = action.payload
  const largeVideoUser = yield select(presentationSelectors.getLargeVideoUser)
  const showVideo = largeVideoUser?.id !== userId
  const isGridView = yield select(gridViewSelectors.getIsGridViewActive)

  if (isGridView) {
    yield put(tracksActions.toggleGridView(false))
    yield put(gridViewActions.disableGridView())
  }

  if (trackService.isUnavailableForLargeVideo(largeVideoUser, showVideo, userId)) {
    return
  }

  if (largeVideoUser && largeVideoUser.id !== userId) {
    yield put(tracksActions.setLargeVideoUser(null))
    yield put(tracksActions.attachLargeVideo({ userId: largeVideoUser.id, isLargeVideo: false }))
  }

  const users = yield select((state) => state.users.users)
  const user = showVideo ? yield users.find((item) => item.id === userId) : null

  yield put(tracksActions.setLargeVideoUser(user))
  yield put(tracksActions.attachLargeVideo({ userId, isLargeVideo: showVideo }))

  /*
   * Elects the participant with the given id to be the selected participant in
   * order to receive higher video quality (if simulcast is enabled).
   */

  if (!showVideo) {
    // Check if video is large. It doesn't check if user has video
    return
  }

  try {
    const state = yield select((state) => state.presentation.tracks)
    const currentUserId = yield select(accountSelectors.getCurrentUserId)
    const track = trackService.getTrack(state, userId, currentUserId, VIDEO_DEVICE)
    if (!track) {
      // Check if user has video track
      return
    }
    const participantId = track.getParticipantId()
    const jitsiRoom = yield select((state) => state.presentation.conference.room)
    jitsiRoom.selectParticipant(participantId)
  } catch (error) {
    console.error('Failed to select participant', error)
  }
}

function* attachLargeVideo(action) {
  const { userId, isLargeVideo } = action.payload

  const $container = isLargeVideo ? trackService.getLargeVideoContainer() : trackService.getVideoContainerById(userId)

  if (!$container) {
    return
  }

  const state = yield select((state) => state.presentation.tracks)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const track = trackService.getTrack(state, userId, currentUserId, VIDEO_DEVICE)
  if (!track) {
    return
  }

  yield put(jitsiTrackActions.detachTrack(track))
  track.attach($container)
}

function* toggleGridView(action) {
  const isGridView = action.payload
  const state = yield select((state) => state.presentation.tracks)
  const users = yield select((state) => usersSelectors.getUsersListForCurrentUser(state))
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const largeVideoUserId = state.largeVideoUser?.id

  isGridView
    ? yield put(tracksActions.setGridUser(users.find((item) => item.id === currentUserId)))
    : yield put(tracksActions.setGridUser(null))

  if (isGridView && largeVideoUserId) {
    yield put(tracksActions.attachLargeVideo(largeVideoUserId, false))
    yield put(tracksActions.setLargeVideoUser(null))
  }

  users.forEach((user) => {
    let $container
    if (isGridView) $container = trackService.getGridVideoContainerById(user.id)
    else if (!isGridView && largeVideoUserId === user.id) $container = trackService.getLargeVideoContainer()
    else $container = trackService.getVideoContainerById(user.id)

    if (!$container) {
      return
    }

    const track = trackService.getTrack(state, user.id, currentUserId, VIDEO_DEVICE)
    if (!track) {
      return
    }
    const { containers } = track
    if (containers.length > 0) {
      track.detach(containers[0])
    }
    track.attach($container)
  })
}

function* removeLocalVideoTrack() {
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  const tracksState = yield select((state) => state.presentation.tracks)
  const oldLocalVideoTrack = tracksState.localTracks.find((item) => item.getType() === VIDEO_DEVICE)

  if (oldLocalVideoTrack) {
    yield call(muteVideo)

    // jitsiRoom is not initialized during the pre-call device test
    if (jitsiRoom) {
      yield call(trackService.replaceTrack, jitsiRoom, oldLocalVideoTrack, null /* new videoTrack */)
    }
    yield put(tracksActions.removeLocalTrack(oldLocalVideoTrack))
    oldLocalVideoTrack.dispose()
  }
}

function* replaceTrack(action) {
  const { track, approvedByLocalRecording } = action.payload
  const type = track.getType()
  const tracksState = yield select((state) => state.presentation.tracks)
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  const localTrack = tracksState.localTracks.find((item) => item.getType() === type)
  const isCallPreview = yield select(getShowCallPreview)

  const isVideoMuteInProgress = yield select((state) => state.presentationToolbar.muteVideoInProgress)
  if (isVideoMuteInProgress && type === VIDEO_DEVICE && !localTrack) {
    yield put(muteVideoActions.onVideoMuted())
  }
  const isAudioMuteInProgress = yield select((state) => state.presentationToolbar.muteAudioInProgress)
  if (isAudioMuteInProgress && type === AUDIO_DEVICE && !localTrack) {
    yield put(muteAudioActions.onAudioMuted())
  }

  if (!jitsiRoom && !isCallPreview) {
    track.dispose()
    return
  }

  if (jitsiRoom) {
    try {
      yield delay(500)

      yield call(trackService.replaceTrack, jitsiRoom, localTrack, track)
    } catch {
      yield put(
        notificationActions.onAddErrorNotification({
          message: `Something wrong with your device.`,
        })
      )
    }
  }

  if (localTrack) {
    localTrack.dispose()
  }

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const participantId = isCallPreview ? currentUserId : parseInt(track.getParticipantId())

  yield put(tracksActions.updateLocalTrack(track))

  if (type === VIDEO_DEVICE) {
    yield put(jitsiTrackActions.attachVideo(participantId))
    if (approvedByLocalRecording) {
      const isCurrentUserPresenter = yield select(getIsCurrentUserPresenter)
      yield put(closeSwitchRecordingSourceDialog())
      if (isCurrentUserPresenter) {
        const selectedUserId = yield select(accountSelectors.getCurrentUserId)
        yield put(recordingActions.onRecordingStarting({ userId: selectedUserId, recordingType: RecordingType.local }))
      } else {
        const contentId = yield select(contentStateSelectors.getContentId)
        yield put(
          signalrActions.invokeHubAction({
            method: 'LocalRecordingOwnerControlRecording',
            args: [contentId, currentUserId, LocalRecordingOwnerActionType.restart],
          })
        )
      }
    }
  }
}

function* bindTrackEvents(action) {
  const track = action.payload
  const jitsiTrackEvents = window.JitsiMeetJS.events.track
  yield fork(
    bindEvent,
    track,
    jitsiTrackEvents.TRACK_MUTE_CHANGED,
    tracksActions.onTrackMuteChanged,
    externalEventChannels
  )
}

function* unbindTrackEvents() {
  externalEventChannels.forEach((channel) => channel.close())
  externalEventChannels = []
}

function* onTrackMuteChanged(action) {
  const track = action.payload
  const userId = parseInt(track.getParticipantId(), 10)

  yield put(usersActions.onUserTracksStateChanged(userId))
}

function* detachTracksForUser(action) {
  const userId = action.payload
  const tracks = yield select((state) => state.presentation.tracks.remoteTracks[userId])
  for (let i = 0; i < tracks.length; i++) {
    yield put(jitsiTrackActions.detachTrack(tracks[i]))
  }
}

function* setAudioOutputDevice(action) {
  const deviceId = action.payload

  try {
    window.JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId)
    localStorage.setItem(AUDIO_OUTPUT_DEVICE_KEY, deviceId)
  } catch {
    yield call(putDeviceErrorNotification, deviceId)
  }
}

function* setAudioInputDevice(action) {
  const audioInputDeviceId = action.payload

  const initTracks = {
    devices: [AUDIO_DEVICE],
    micDeviceId: audioInputDeviceId,
  }

  try {
    const newTracks = yield call(connectionService.createLocalTracks, initTracks)
    const audioTrack = newTracks.find((track) => track.getType() === AUDIO_DEVICE)
    yield put(tracksActions.replaceTrack({ track: audioTrack }))
    localStorage.setItem(AUDIO_INPUT_DEVICE_KEY, audioInputDeviceId)
  } catch {
    yield call(putDeviceErrorNotification, audioInputDeviceId)
  }
}

function* setVideoInputDevice(action) {
  const isScreenSharing = yield select((state) => presentationSelectors.getIsScreenSharing(state))
  const isScreenSharingInProgress = yield select((state) => presentationSelectors.getIsScreenSharingInProgress(state))

  if (isScreenSharing || isScreenSharingInProgress) {
    return
  }

  const { deviceId, approvedByLocalRecording, muted = false } = action.payload

  const initTracks = {
    devices: [VIDEO_DEVICE],
    cameraDeviceId: deviceId,
  }

  try {
    const newTracks = yield call(connectionService.createLocalTracks, initTracks)
    const videoTrack = newTracks.find((track) => track.getType() === VIDEO_DEVICE)
    if (muted) {
      yield call([videoTrack, videoTrack.mute])
      yield put(setVideoMuted())
      yield put(onVideoMuted())
    }
    yield put(tracksActions.replaceTrack({ track: videoTrack, approvedByLocalRecording }))
    localStorage.setItem(VIDEO_INPUT_DEVICE_KEY, deviceId)
  } catch {
    yield call(removeLocalVideoTrack)
    yield call(putDeviceErrorNotification, deviceId)
  }
}

function* loadCameraPreviews(action) {
  const videoDevices = yield call(conferenceService.getDevices, VIDEO_INPUT_KIND)
  yield call(unloadCameraPreviews)
  for (let i = 0; i < videoDevices.length; i++) {
    const device = videoDevices[i]

    const initTracks = {
      devices: [VIDEO_DEVICE],
      cameraDeviceId: device.deviceId,
    }
    try {
      const tracks = yield call(connectionService.createLocalTracks, initTracks)
      const container = yield call(trackService.getPreviewVideoContainerById, device.deviceId)

      const videoTrack = tracks.find((track) => track.getType() === VIDEO_DEVICE)
      if (container == null) {
        videoTrack.dispose()
      } else {
        yield put(tracksActions.addPreviewTrack(videoTrack))
        videoTrack.attach(container)
      }
    } catch {
      yield call(putDeviceErrorNotification, device.deviceId)
    }
  }
}

function* unloadCameraPreviews(action) {
  const previewTracks = yield select((state) => presentationSelectors.getPreviewTracks(state))

  previewTracks.forEach((track) => {
    track.dispose()
  })

  yield put(tracksActions.resetPreviewTracks())
}

function* putDeviceErrorNotification(deviceId) {
  const device = yield call(conferenceService.getDeviceById, deviceId)
  const deviceLabel = device?.label

  if (!deviceLabel) {
    return
  }

  yield put(
    notificationActions.onAddErrorNotification({
      message: `${deviceLabel} is not available.`,
    })
  )
}

function* flipMobileCamera() {
  const localTrack = yield select((state) =>
    state.presentation.tracks.localTracks.find((item) => item.getType() === VIDEO_DEVICE)
  )

  if (!localTrack || localTrack.isMuted()) {
    return
  }

  yield call(muteVideo)

  const currentFacingMode = localTrack._facingMode

  const targetFacingMode =
    currentFacingMode === CAMERA_FACING_MODE.USER ? CAMERA_FACING_MODE.ENVIRONMENT : CAMERA_FACING_MODE.USER

  const initTracks = {
    devices: [VIDEO_DEVICE],
    facingMode: targetFacingMode,
  }

  yield delay(500)
  try {
    const tracks = yield call(connectionService.createLocalTracks, initTracks)
    const videoTrack = tracks.find((track) => track.getType() === VIDEO_DEVICE)
    yield put(tracksActions.replaceTrack({ track: videoTrack }))

    const capabilities = videoTrack.track.getCapabilities()
    const hasZoom = Boolean(capabilities.zoom)

    const user = yield select(usersSelectors.getCurrentUser)
    const minZoom = parseInt(mobileZoomMin, 10)
    const maxZoom = hasZoom ? capabilities.zoom.max * ZOOM_MULTIPLIER : minZoom

    if (user.mobileZoomValue > minZoom) {
      const contentId = yield select(getContentId)
      yield put(
        signalrActions.invokeHubAction({
          method: 'UpdateZoomingValue',
          args: [contentId, user.id, minZoom, maxZoom],
        })
      )
    }

    yield put(devicesActions.setMobileCameraFacingMode(targetFacingMode))
  } catch (e) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: `Something wrong with your device.`,
      })
    )
  }
}

function* onShowCallPreview() {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  let constraints = yield call(conferenceService.checkForDevices)
  constraints = constraints.filter((item) => item !== '')

  if (constraints.length === 0) {
    return
  }

  const audioInputDeviceId = DEFAULT_AUDIO_DEVICE_ID
  const videoDevices = yield call(conferenceService.getVideoInputDevices)
  const videoInputDeviceId = videoDevices[0] ? videoDevices[0].deviceId : DEFAULT_VIDEO_DEVICE_ID

  const initTracks = {
    devices: constraints,
    micDeviceId: audioInputDeviceId,
    cameraDeviceId: videoInputDeviceId,
  }
  try {
    yield call(connectionService.initJitsi)

    const tracks = yield call(connectionService.createLocalTracks, initTracks)
    yield put(tracksActions.setLocalTracks(tracks))

    const videoContainer = yield call(trackService.getVideoContainerById, currentUserId)
    const videoTrack = tracks.find((track) => track.getType() === VIDEO_DEVICE)
    if (videoContainer == null) {
      videoTrack.dispose()
    } else {
      yield put(devicesActions.setVideoInputDevice({ deviceId: videoInputDeviceId }))
      videoTrack.attach(videoContainer)
    }
  } catch (ex) {
    yield put(usersActions.resetVideoLoadForUser(currentUserId))
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'No camera or mic is detected. To make a video call please plug a webcam or mic into your device',
        autoDismiss: 10,
      })
    )
  }
}

function* onHideCallPreview() {
  const localTracks = yield select((state) => state.presentation.tracks.localTracks)

  localTracks.forEach((track) => {
    track.dispose()
  })

  yield put(tracksActions.resetLocalTracks())
}

function* toggleMobileLargeVideo(action) {
  if (!isMobileOnly) {
    return
  }

  const isPriorityView = yield select(presentationSelectors.getIsPriorityViewEnabled)
  const isGridView = yield select(gridViewSelectors.getIsGridViewActive)
  const isUserList = yield select(getShowUserList)
  const largeVideoUser = yield select(presentationSelectors.getLargeVideoUser)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  // turn off
  if (!isPriorityView && !isGridView && !isUserList) {
    if (largeVideoUser.id === currentUserId) {
      yield put(tracksActions.setLargeVideoUser(null))
      yield put(tracksActions.attachLargeVideo({ userId: largeVideoUser.id, isLargeVideo: false }))
    }

    yield put(tracksActions.toggleMobilePriorityView(true))
    return
  }

  // turn on from priority view

  if (isPriorityView) {
    yield put(tracksActions.toggleMobilePriorityView(false))
  }

  // turn on from grid view

  if (isGridView) {
    yield put(gridViewActions.disableGridView())
  }

  // turn on from user list

  if (isUserList) {
    yield put(hideUserList({ turnOnPriorityMode: false }))
  }

  if (largeVideoUser && largeVideoUser.id === action.payload.userId) {
    return
  }

  yield put(tracksActions.toggleLargeVideo(action.payload))
}

function* onShowMobileContactList() {
  const isPriorityView = yield select(presentationSelectors.getIsPriorityViewEnabled)
  const largeVideoUser = yield select(presentationSelectors.getLargeVideoUser)

  if (isPriorityView) {
    yield put(tracksActions.toggleMobilePriorityView(false))
  }

  if (largeVideoUser) {
    yield put(tracksActions.setLargeVideoUser(null))
    yield put(tracksActions.attachLargeVideo({ userId: largeVideoUser.id, isLargeVideo: false }))
  }
}

function* onHideMobileContactList({ payload }) {
  const { turnOnPriorityMode } = payload

  if (turnOnPriorityMode) {
    yield put(tracksActions.toggleMobilePriorityView(true))
    yield put(showSelfView())
  }
}

function* toggleMobileUserList({ payload }) {
  const isUserList = payload

  const state = yield select((state) => state.presentation.tracks)
  const users = yield select((state) => usersSelectors.getUsersListForCurrentUser(state))
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  const isPriorityView = yield select(presentationSelectors.getIsPriorityViewEnabled)
  const largeVideoUser = yield select(presentationSelectors.getLargeVideoUser)

  if (isUserList && isPriorityView) {
    yield put(tracksActions.toggleMobilePriorityView(false))
  }

  if (isUserList && largeVideoUser) {
    yield put(tracksActions.setLargeVideoUser(null))
    yield put(tracksActions.attachLargeVideo({ userId: largeVideoUser.id, isLargeVideo: false }))
  }

  users.forEach((user) => {
    let $container
    if (largeVideoUser?.id === user.id) $container = trackService.getLargeVideoContainer()
    else $container = trackService.getVideoContainerById(user.id)

    if (!$container) {
      return
    }

    const track = trackService.getTrack(state, user.id, currentUserId, VIDEO_DEVICE)
    if (!track) {
      return
    }

    const { containers } = track
    if (containers.length > 0) {
      track.detach(containers[0])
    }
    track.attach($container)
  })
}

function* toggleMobileFlash({ payload }) {
  const localTrack = yield select((state) =>
    state.presentation.tracks.localTracks.find((item) => item.getType() === VIDEO_DEVICE)
  )

  const capabilities = localTrack.track.getCapabilities()

  if (!capabilities.torch) {
    return
  }

  yield localTrack.track.applyConstraints({
    advanced: [{ torch: payload }],
  })
}

function* updateMobileZoom({ payload }) {
  const { userId, contentId, zoomValue } = payload

  const localTrack = yield select((state) =>
    state.presentation.tracks.localTracks.find((item) => item.getType() === VIDEO_DEVICE)
  )
  try {
    const capabilities = localTrack.track.getCapabilities()

    if (!capabilities.zoom || zoomValue > capabilities.zoom.max) {
      return
    }

    yield put(
      signalrActions.invokeHubAction({
        method: 'UpdateZoomingValue',
        args: [contentId, userId, zoomValue * ZOOM_MULTIPLIER, capabilities.zoom.max * ZOOM_MULTIPLIER],
      })
    )

    yield localTrack.track.applyConstraints({
      advanced: [{ zoom: zoomValue }],
    })
  } catch (e) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'An error occurred while zooming.',
        autoDismiss: 10,
      })
    )
  }
}

function* handleSettingMutedDeviceState({ payload: newTrack }) {
  const callPreviewOpen = yield select(presentationSelectors.getIsCallPreviewOpen)
  if (!callPreviewOpen) {
    return
  }

  const newTrackType = newTrack.getType()
  switch (newTrackType) {
    case AUDIO_DEVICE:
      const parsedBooleanAudioMuted = JSON.parse(localStorage.getItem(AUDIO_MUTED))
      if (parsedBooleanAudioMuted === true) {
        yield put(muteAudioActions.muteAudio())
      }
      break
    case VIDEO_DEVICE:
      const parsedBooleanVideoMuted = JSON.parse(localStorage.getItem(VIDEO_MUTED))
      if (parsedBooleanVideoMuted === true) {
        yield put(muteVideoActions.muteVideo())
      }
      break
  }
}

function* tracksSaga() {
  yield all([
    takeLatest(tracksActions.createLocalTracks, createLocalTracks),
    takeLatest(tracksActions.attachTrack, attachTrack),
    takeLatest(muteAudioActions.muteAudio, muteAudio),
    takeLatest(muteVideoActions.muteVideo, muteVideo),
    takeLatest(tracksActions.stopTracks, stopTracks),
    takeLatest(tracksActions.toggleLargeVideo, toggleLargeVideo),
    takeLatest(tracksActions.attachLargeVideo, attachLargeVideo),
    takeEvery(tracksActions.replaceTrack, replaceTrack),
    takeLatest(tracksActions.removeLocalVideoTrack, removeLocalVideoTrack),
    takeLatest(tracksActions.bindTrackEvents, bindTrackEvents),
    takeLatest(tracksActions.unbindTrackEvents, unbindTrackEvents),
    takeLatest(tracksActions.onTrackMuteChanged, onTrackMuteChanged),
    takeLatest(tracksActions.detachTracksForUser, detachTracksForUser),
    takeEvery(devicesActions.setAudioOutputDevice, setAudioOutputDevice),
    takeEvery(devicesActions.setAudioInputDevice, setAudioInputDevice),
    takeEvery(devicesActions.setVideoInputDevice, setVideoInputDevice),
    takeEvery(devicesActions.loadCameraPreviews, loadCameraPreviews),
    takeEvery(devicesActions.unloadCameraPreviews, unloadCameraPreviews),
    takeLatest(devicesActions.flipMobileCamera, flipMobileCamera),
    takeLatest(tracksActions.toggleGridView, toggleGridView),
    takeLatest(showCallPreview, onShowCallPreview),
    takeLatest(hideCallPreview, onHideCallPreview),
    takeLatest(tracksActions.toggleMobileLargeVideo, toggleMobileLargeVideo),
    takeLatest(showUserList, onShowMobileContactList),
    takeLatest(hideUserList, onHideMobileContactList),
    takeLatest(tracksActions.toggleMobileUserList, toggleMobileUserList),
    takeLatest(tracksActions.toggleMobileFlash, toggleMobileFlash),
    takeLatest(tracksActions.updateMobileZoom, updateMobileZoom),
    takeEvery(tracksActions.updateLocalTrack, handleSettingMutedDeviceState),
  ])
}

export default tracksSaga
